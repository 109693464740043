import React, { useRef, useEffect, useState } from "react";
import $ from "jquery";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Accordion from "react-bootstrap/Accordion";
import Lottie from "react-lottie-player";
import lottieJson from "../assets/js/68314.json";
import lottieJson2 from "../assets/js/73297.json";
import lottieJson3 from "../assets/js/71665.json";
import lottieJson4 from "../assets/js/71666.json";
import loader from "../assets/js/loader.json";
import fb from "../assets/js/fb.json";

// import { gsap, ScrollTrigger, Draggable, MotionPathPlugin } from "gsap/all";

gsap.registerPlugin(ScrollTrigger);

window.scrollTo(0, 0);

setTimeout(function () {
    window.scrollTo(0, 0);
    $(".loader").fadeOut("fast");
}, 3500);
setTimeout(function () {
    $(".bear1").css("bottom", "-10%");
}, 4000);

$(".faqs-section").on("scroll", function () {
    if ($(this).scrollTop() == "0") {
        alert("End of DIV is reached!");
    }
});

function Home() {
    //   const [faq1, setfaq1] = useState(true);
    //   const [faq2, setfaq2] = useState(false);
    //   const [faq3, setfaq3] = useState(false);
    //   const [faq4, setfaq4] = useState(false);
    //   const [faq5, setfaq5] = useState(false);
    //   const [faq6, setfaq6] = useState(false);
    //   const [stateImage, setStateImage] = useState({
    //     faq1: true,
    //     faq2: false,
    //     faq3: false,
    //     faq4: false,
    //     faq5: false,
    //     faq6: false,
    //   });
    const [imageUrl, setImageUrl] = useState('faq-bear');
    const handleImage = (name) => {
        setImageUrl(name);
        //  console.log(">>>>>>>>",name)
        // Object.keys(stateImage).map(function (key) {
        //     console.log(key,name);
        //     if(name == key ){
        //         console.log("Matched")
        //         setStateImage({
        //             [key]: !stateImage[key],
        //         })
        //     } else {
        //         setStateImage({
        //             [key]: false
        //         })
        //     }
        // })

    }
    // const settings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 3,
    //     slidesToScroll: 1,
    //     arrows: false,
    //     responsive: [
    //         {
    //             breakpoint: 1025,
    //             settings: {
    //                 slidesToShow: 2,
    //                 slidesToScroll: 1,
    //                 infinite: true,
    //             }
    //         },
    //         {
    //             breakpoint: 768,
    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1,
    //                 infinite: true,
    //             }
    //         }
    //     ]
    // };
    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: ".top-banner-area ",
            start: "top",
            end: "bottom bottom",
            scrub: true,
            // toggleActions: "play none none reverse"
        },
    });
    const bear = gsap.timeline({
        scrollTrigger: {
            trigger: ".top-banner-area ",
            start: "top",
            end: "500",
            scrub: false,
            toggleActions: "play none none reverse",
        },
    });

    useEffect(() => {
        window.scrollTo(0, 0);

        // tl.to(".planet1", { 'width': '35%', 'transform': 'rotate(20deg)', duration: 4, ease: "slow(0.7, 0.7, false)" })
        // tl.to(".planet2", { 'right': '18%', 'top': '20%', 'transform': 'rotate(20deg)', duration: 4, ease: "slow(0.7, 0.7, false)" }, "-=4")
        // tl.to(".planet3", { 'left': '46%', 'top': '50%', 'transform': 'rotate(20deg)', duration: 4, ease: "slow(0.7, 0.7, false)" }, "-=4")
        // tl.to(".rock3", { 'right': '33%', duration: 4 }, "-=4")
        // tl.to(".rock4", { 'right': '30%', 'top': '50%', duration: 4 }, "-=4")
        // tl.to(".rock6", { 'right': '35%', 'top': '63%', duration: 4 }, "-=4")
        // tl.to(".rock1", { 'right': '14%', 'top': '65%', duration: 4 }, "-=4")
        // tl.to(".planet1", { 'width': '20%', duration: 4 })
        // tl.to(".planet2", { 'width': '10%', duration: 4 }, "-=4")
        // tl.to(".rock1", { 'top': '55%', duration: 4 }, "-=4")
        // tl.from(".bear1", { 'bottom': '-100%', duration: 4 })
        // tl.to(".bear1", {
        //     'bottom': '-10%', duration: 2,
        //     //  onComplete: function () {

        //     //     const bear1 = gsap.timeline({
        //     //         scrollTrigger: {
        //     //             trigger: ".top-banner-area",
        //     //             start: "top",
        //     //             //   marker: true,
        //     //             scrub: false,
        //     //             toggleActions: "play none none reverse"
        //     //         }
        //     //     });
        //     //     bear1.to(".bear1 img", { scale: 2.3, duration: 1, y: '120%', x: '-25%' })

        //     //     bear1.to(".bear", { autoAlpha: '1', 'top': '185px', 'z-index': '99999', duration: 1 }, "-=1")

        //     // }
        // })
        if ($(window).width() < "992") {
            tl.to(".bear1 img", { scale: 2.3, duration: 3, y: "35%", x: "-25%" });
            tl.to(
                ".bear",
                {
                    autoAlpha: "1",
                    top: "185px",
                    "z-index": "99999",
                    duration: 2,
    
                }
            );    
            if ($(window).width() < "600") {
                tl.to(".world", {
                    scale: 1,
                    x: "0%",
                    y: "25%",
                    autoAlpha: "1",
                    "text-align": "center",
                    rotation: 0,
                    duration: 4,
                    delay: 4,
                });
            }
            else {
                tl.to(".world", {
                    scale: 1,
                    x: "0%",
                    y: "15%",
                    autoAlpha: "1",
                    "text-align": "center",
                    rotation: 0,
                    duration: 4,
                    delay: 4,
                });
            }

            tl.to(
                ".bear",
                { autoAlpha: "0", "z-index": "9", duration: 2 },
                "-=4"
            );
            tl.to(".bear1 img", { autoAlpha: "0", duration: 2 }, "-=4");
            tl.to(
                ".world-content",
                { autoAlpha: "1", right: "0", "z-index": "99999", duration: 2 },
                "-=1"
            );
            tl.to(".world", { scale: 2.5, duration: 4, delay: 4 });
            tl.to(
                ".world-content",
                { autoAlpha: "0", "z-index": "9", duration: 4 },
                "-=4"
            );
            tl.to(
                ".jungle",
                {
                    width: "100%",
                    top: "0",
                    left: "0",
                    autoAlpha: "1",
                    "text-align": "center",
                    height: "100vh",
                    duration: 4,
                },
                "-=4"
            );
            tl.to(".world", { display: "none", duration: 4 }, "-=4");
            tl.to(".jungle-content", {
                autoAlpha: "1",
                "z-index": "99999",
                duration: 4,
            });
            tl.to(".jungle", {
                scale: 2.5,
                autoAlpha: "0",
                duration: 4,
                delay: 2,
            });
            tl.to(
                ".jungle-content .content",
                { autoAlpha: "0", display: "none", "z-index": "9", duration: 4 },
                "-=4"
            );
            tl.to(".home-outer", { autoAlpha: "1", duration: 4 }, "-=4");
            tl.to(".bear-home.content ", {
                display: "block",
                autoAlpha: "1",
                "z-index": "99999",
                duration: 2,
            });
            tl.to(".home-outer", {
                scale: 3,
                autoAlpha: "0",
                duration: 4,
                delay: 2,
            });
            tl.to(".bear-home ", { autoAlpha: "0", duration: 4 }, "-=4");
            tl.to(".home-inner", { autoAlpha: "1", duration: 4 }, "-=4");
            tl.to(
                ".bear-home.content ",
                { display: "none", autoAlpha: "0", "z-index": "9", duration: 2 },
                "-=4"
            );
            tl.to(".bear-home-inner.content ", {
                display: "block",
                autoAlpha: "1",
                "z-index": "99999",
                duration: 2,
            });
            tl.to(".bear4", { bottom: "0%", duration: 4 }, "-=2");
            tl.to(".bear2", { bottom: "0%", duration: 4 }, "-=2");
            tl.to(".bear3", { bottom: "0%", duration: 4 }, "-=4");
            tl.to(".bear5", { bottom: "0%", duration: 4 }, "-=4");
            tl.to(".bear6", { bottom: "0%", duration: 4 }, "-=4");
            tl.to(
                ".bear-home-inner ",
                { autoAlpha: "1", "z-index": "9", duration: 4 },
                "-=2"
            );
            tl.to(".bear3", { left: "-100%", duration: 4 });
            tl.to(".bear2", { left: "-100%", duration: 4 }, "-=4");
            tl.to(".bear5", { right: "-100%", duration: 4 }, "-=4");
            tl.to(".bear6", { right: "-100%", duration: 4 }, "-=4");
            tl.to(".bear4", { left: "5%", width: "32%", duration: 4 }, "-=3");
            tl.to(".bear-home-inner ", { autoAlpha: "0", duration: 4 }, "-=4");
            tl.to(
                ".jungle-content",
                { autoAlpha: "0", "z-index": "9", duration: 4 },
                "-=4"
            );
            tl.to(".road-map", { autoAlpha: "1", duration: 4 }, "-=4");
            tl.to(
                ".road-map-content .title",
                { autoAlpha: "1", "z-index": "99999", duration: 4 },
                "-=3"
            );
            tl.to(".bear6", { autoAlpha: "0", rotateY: 28, duration: 4 }, "-=4");
            tl.to(
                ".road-map-content .list.one",
                { autoAlpha: "1", "z-index": "99999", duration: 4, delay: 3 },
                "-=4"
            );
            tl.to(".bear4", { left: "-100%", duration: 4 });
            tl.to(".bear6", { right: "5%", width: "34%", duration: 4 }, "-=4");
            tl.to(
                ".list.one",
                { autoAlpha: "0", "margin-top": "-250px", duration: 4 },
                "-=4"
            );

            tl.to(".bear2", { left: "0%", width: "38%", duration: 4 }, "-=4");
            tl.to(
                ".list.two",
                {
                    autoAlpha: "1",
                    "margin-top": "0",
                    "z-index": "99999",
                    duration: 4,
                },
                "-=3"
            );
            tl.to(".bear2", { left: "-100%", duration: 4, delay: 5 });
            tl.to(
                ".list.two",
                {
                    autoAlpha: "0",
                    "margin-right": "-300px",
                    "z-index": "9",
                    duration: 4,
                },
                "-=4"
            );
            tl.to(".bear6", { rotateY: 0, autoAlpha: "1", duration: 4 }, "-=2");
            tl.to(
                ".list.three",
                {
                    autoAlpha: "1",
                    "margin-left": "3%",
                    "z-index": "99999",
                    duration: 4,
                },
                "-=4"
            );
            tl.to(".list.three", {
                autoAlpha: "0",
                "margin-top": "-100px",
                "z-index": "9",
                duration: 4,
                delay: 5,
            });
            tl.to(".list.four", {
                autoAlpha: "1",
                "z-index": "99999",
                duration: 4,
            });
            tl.to(".end-right", { right: "-50%", duration: 4, delay: 5 });
            tl.to(".end-left", { left: "-50%", duration: 4 }, "-=4");
            tl.to(".bear6", { autoAlpha: "0", duration: 2 }, "-=1");
            tl.to(
                ".road-map-content .title",
                { autoAlpha: "0", duration: 4 },
                "-=4"
            );
            tl.to(
                ".list.four",
                { autoAlpha: "0", "z-index": "9", duration: 4 },
                "-=4"
            );
            tl.to(".teams-slider", {
                autoAlpha: "1",
                "z-index": "99999",
                duration: 4,
            });
            tl.to(".end-right", { right: "-100%", duration: 4 }, "-=2");
            tl.to(".end-left", { left: "-100%", duration: 4 }, "-=4");
            tl.to(".teams-slider .item", { rotateY: 0, duration: 4 }, "-=2");
            tl.to(".team", { x: "-80%", duration: 8 });
            tl.to(".top-banner-area  *", {
                autoAlpha: "0",
                "z-index": "9",
                duration: 2,
            });
            tl.to(".top-banner-area", { autoAlpha: "0", duration: 2 }, "-=2");
            tl.to(".teams-slider", {
                autoAlpha: "0",
                "z-index": "999",
                duration: 1,
            });
            tl.to(
                ".faqs-section .q-image span",
                {
                    width: "20px",
                    height: "20px",
                    left: "0",
                    bottom: "-30px",
                    autoAlpha: "1",
                    duration: 2,
                },
                "-=4"
            );
            tl.to(".faqs-section", {
                "z-index": "99999",
                duration: 2,

                onComplete: function () {
                    gsap.set("body", { overflow: "hidden" });
                    gsap.set("body", { overflow: "auto", delay: 1 });
                },
            });
        } else {
            bear.to(".bear1 img", { scale: 2.3, duration: 2, y: "120%", x: "-25%" });
        

        bear.to(
            ".bear",
            {
                autoAlpha: "1",
                top: "185px",
                "z-index": "99999",
                duration: 1,


                onComplete: function () {
                    if ($(window).width() < "1400") {
                        if ($(window).width() < "992") {
                            if ($(window).width() < "600") {
                                tl.to(".world", {
                                    scale: 1,
                                    x: "0%",
                                    y: "25%",
                                    autoAlpha: "1",
                                    "text-align": "center",
                                    rotation: 0,
                                    duration: 4,
                                    delay: 4,
                                });
                            }
                            else {
                                tl.to(".world", {
                                    scale: 1,
                                    x: "0%",
                                    y: "15%",
                                    autoAlpha: "1",
                                    "text-align": "center",
                                    rotation: 0,
                                    duration: 4,
                                    delay: 4,
                                });
                            }
                        } else {
                            tl.to(".world", {
                                scale: 0.6,
                                x: "2%",
                                y: "-20%",
                                autoAlpha: "1",
                                "text-align": "center",
                                rotation: 0,
                                duration: 4,
                                delay: 2,
                            });
                        }
                    } else {
                        tl.to(".world", {
                            scale: 1,
                            x: "20%",
                            y: "-13%",
                            autoAlpha: "1",
                            "text-align": "center",
                            rotation: 0,
                            duration: 4,
                            delay: 3,
                        });
                    }

                    tl.to(
                        ".bear",
                        { autoAlpha: "0", "z-index": "9", duration: 2 },
                        "-=4"
                    );
                    tl.to(".bear1 img", { autoAlpha: "0", duration: 2 }, "-=4");
                    tl.to(
                        ".world-content",
                        { autoAlpha: "1", right: "0", "z-index": "99999", duration: 2 },
                        "-=1"
                    );
                    tl.to(".world", { scale: 2.5, duration: 4, delay: 4 });
                    tl.to(
                        ".world-content",
                        { autoAlpha: "0", "z-index": "9", duration: 4 },
                        "-=4"
                    );
                    tl.to(
                        ".jungle",
                        {
                            width: "100%",
                            top: "0",
                            left: "0",
                            autoAlpha: "1",
                            "text-align": "center",
                            height: "100vh",
                            duration: 4,
                        },
                        "-=4"
                    );
                    tl.to(".world", { display: "none", duration: 4 }, "-=4");
                    tl.to(".jungle-content", {
                        autoAlpha: "1",
                        "z-index": "99999",
                        duration: 4,
                    });
                    tl.to(".jungle", {
                        scale: 2.5,
                        autoAlpha: "0",
                        duration: 4,
                        delay: 2,
                    });
                    tl.to(
                        ".jungle-content .content",
                        { autoAlpha: "0", display: "none", "z-index": "9", duration: 4 },
                        "-=4"
                    );
                    tl.to(".home-outer", { autoAlpha: "1", duration: 4 }, "-=4");
                    tl.to(".bear-home.content ", {
                        display: "block",
                        autoAlpha: "1",
                        "z-index": "99999",
                        duration: 2,
                    });
                    tl.to(".home-outer", {
                        scale: 3,
                        autoAlpha: "0",
                        duration: 4,
                        delay: 2,
                    });
                    tl.to(".bear-home ", { autoAlpha: "0", duration: 4 }, "-=4");
                    tl.to(".home-inner", { autoAlpha: "1", duration: 4 }, "-=4");
                    tl.to(
                        ".bear-home.content ",
                        { display: "none", autoAlpha: "0", "z-index": "9", duration: 2 },
                        "-=4"
                    );
                    tl.to(".bear-home-inner.content ", {
                        display: "block",
                        autoAlpha: "1",
                        "z-index": "99999",
                        duration: 2,
                    });
                    tl.to(".bear4", { bottom: "0%", duration: 4 }, "-=2");
                    tl.to(".bear2", { bottom: "0%", duration: 4 }, "-=2");
                    tl.to(".bear3", { bottom: "0%", duration: 4 }, "-=4");
                    tl.to(".bear5", { bottom: "0%", duration: 4 }, "-=4");
                    tl.to(".bear6", { bottom: "0%", duration: 4 }, "-=4");
                    tl.to(
                        ".bear-home-inner ",
                        { autoAlpha: "1", "z-index": "9", duration: 4 },
                        "-=2"
                    );
                    tl.to(".bear3", { left: "-100%", duration: 4 });
                    tl.to(".bear2", { left: "-100%", duration: 4 }, "-=4");
                    tl.to(".bear5", { right: "-100%", duration: 4 }, "-=4");
                    tl.to(".bear6", { right: "-100%", duration: 4 }, "-=4");
                    tl.to(".bear4", { left: "5%", width: "32%", duration: 4 }, "-=3");
                    tl.to(".bear-home-inner ", { autoAlpha: "0", duration: 4 }, "-=4");
                    tl.to(
                        ".jungle-content",
                        { autoAlpha: "0", "z-index": "9", duration: 4 },
                        "-=4"
                    );
                    tl.to(".road-map", { autoAlpha: "1", duration: 4 }, "-=4");
                    tl.to(
                        ".road-map-content .title",
                        { autoAlpha: "1", "z-index": "99999", duration: 4 },
                        "-=3"
                    );
                    tl.to(".bear6", { autoAlpha: "0", rotateY: 28, duration: 4 }, "-=4");
                    tl.to(
                        ".road-map-content .list.one",
                        { autoAlpha: "1", "z-index": "99999", duration: 4, delay: 3 },
                        "-=4"
                    );
                    tl.to(".bear4", { left: "-100%", duration: 4 });
                    tl.to(".bear6", { right: "5%", width: "34%", duration: 4 }, "-=4");
                    tl.to(
                        ".list.one",
                        { autoAlpha: "0", "margin-top": "-250px", duration: 4 },
                        "-=4"
                    );

                    tl.to(".bear2", { left: "0%", width: "38%", duration: 4 }, "-=4");
                    tl.to(
                        ".list.two",
                        {
                            autoAlpha: "1",
                            "margin-top": "0",
                            "z-index": "99999",
                            duration: 4,
                        },
                        "-=3"
                    );
                    tl.to(".bear2", { left: "-100%", duration: 4, delay: 5 });
                    tl.to(
                        ".list.two",
                        {
                            autoAlpha: "0",
                            "margin-right": "-300px",
                            "z-index": "9",
                            duration: 4,
                        },
                        "-=4"
                    );
                    tl.to(".bear6", { rotateY: 0, autoAlpha: "1", duration: 4 }, "-=2");
                    tl.to(
                        ".list.three",
                        {
                            autoAlpha: "1",
                            "margin-left": "3%",
                            "z-index": "99999",
                            duration: 4,
                        },
                        "-=4"
                    );
                    // tl.to(".list.three", {
                    //     autoAlpha: "0",
                    //     "margin-top": "-100px",
                    //     "z-index": "9",
                    //     duration: 4,
                    //     delay: 5,
                    // });
                    // tl.to(".list.four", {
                    //     autoAlpha: "1",
                    //     "z-index": "99999",
                    //     duration: 4,
                    // });
                    tl.to(".end-right", { right: "-50%", duration: 4, delay: 5 });
                    tl.to(".end-left", { left: "-50%", duration: 4 }, "-=4");
                    tl.to(".bear6", { autoAlpha: "0", duration: 2 }, "-=1");
                    tl.to(
                        ".road-map-content .title",
                        { autoAlpha: "0", duration: 4 },
                        "-=4"
                    );
                    tl.to(
                        ".list.three",
                        { autoAlpha: "0", "z-index": "9", duration: 4 },
                        "-=4"
                    );
                    tl.to(".teams-slider", {
                        autoAlpha: "1",
                        "z-index": "99999",
                        duration: 4,
                    });
                    tl.to(".end-right", { right: "-100%", duration: 4 }, "-=2");
                    tl.to(".end-left", { left: "-100%", duration: 4 }, "-=4");
                    tl.to(".teams-slider .item", { rotateY: 0, duration: 4 }, "-=2");
                    tl.to(".team", { x: "-80%", duration: 8 });
                    tl.to(".top-banner-area  *", {
                        autoAlpha: "0",
                        "z-index": "9",
                        duration: 2,
                    });
                    tl.to(".top-banner-area", { autoAlpha: "0", duration: 2 }, "-=2");
                    tl.to(".teams-slider", {
                        autoAlpha: "0",
                        "z-index": "999",
                        duration: 1,
                    });
                    tl.to(
                        ".faqs-section .q-image span",
                        {
                            width: "20px",
                            height: "20px",
                            left: "0",
                            bottom: "-30px",
                            autoAlpha: "1",
                            duration: 2,
                        },
                        "-=4"
                    );
                    tl.to(".faqs-section", {
                        "z-index": "99999",
                        duration: 2,

                        onComplete: function () {
                            gsap.set("body", { overflow: "hidden" });
                            gsap.set("body", { overflow: "auto", delay: 1 });
                        },
                    });
                },
            },
            "-=1"
        );
    }

        // tl.to(".world",{ rotation: -80,duration: 4,autoAlpha: 0.8,

        //     onComplete: function () {
        //                 // $(".bear1").addClass('mainAnimatedClass'); // then only replace with blue div with new height and width
        //                 const world1 = gsap.timeline({
        //                     scrollTrigger: {
        //                         trigger: ".top-banner-area",
        //                         start: "top",
        //                         //   marker: true,
        //                         scrub: false,
        //                         toggleActions: "play none none reverse"
        //                     }
        //                 });
        //                 if ($(window).width() < '1400') {
        //                     world1.to(".world", { width: '60%', 'top': '-8%', 'left': '0', 'right': '0', autoAlpha: '1', 'text-align': 'center', rotation: 0, duration: 4 })
        //                 }
        //                 else {
        //                     world1.to(".world", { 'width': '100%', 'top': '-15%', 'left': '0', autoAlpha: '1', 'text-align': 'center', rotation: 0, duration: 4 })
        //                 }

        //                 world1.to(".bear", { autoAlpha: '0', 'z-index': '9', duration: 2 }, "-=2")
        //                 world1.to(".bear1 img", { autoAlpha: '0', duration: 2 }, "-=2")
        //                 world1.to(".world-content", { autoAlpha: '1', 'right': '0', 'z-index': '99999', duration: 2 }, "-=1")
        //             }
        // })

        // tl.to(".faqs-section .icon-holder", { 'width': '145px', 'height': '145px', 'left': '0', 'top': '0', duration: 2 }, "-=4")
    }, []);

    return (
        <main>
            <div className="loader">
                <img src="images/loader.gif" alt="loader 1" className="img-fluid" />
                <Lottie className="loader-dots" loop animationData={loader} play />
            </div>
            <div className="top-banner-area">
                <div className="star1 star icon-holder">
                    <Lottie loop animationData={lottieJson} play />
                </div>
                <div className="star2 star icon-holder">
                    <Lottie loop animationData={lottieJson} play />
                </div>
                <div className="star3 star icon-holder">
                    <Lottie loop animationData={lottieJson} play />
                </div>
                <div className="star4 star icon-holder">
                    <Lottie loop animationData={lottieJson} play />
                </div>
                <div className="shinnig-stars icon-holder">
                    <Lottie loop animationData={lottieJson2} play />
                </div>
                <div className="planets">
                    <div className="planet1">
                        <img
                            src="images/planet1.svg"
                            alt="planet 1"
                            className="img-fluid"
                        />
                    </div>
                    <div className="planet2">
                        <img
                            src="images/planet2.svg"
                            alt="planet 2"
                            className="img-fluid"
                        />
                    </div>
                    <div className="planet3">
                        <img
                            src="images/planet3.svg"
                            alt="planet 3"
                            className="img-fluid"
                        />
                    </div>
                    <div className="planet4">
                        <img
                            src="images/planet4.svg"
                            alt="planet 4"
                            className="img-fluid"
                        />
                    </div>
                    <div className="rock1">
                        <img src="images/rock1.svg" alt="Rock 1" className="img-fluid" />
                    </div>
                    <div className="rock2">
                        <img src="images/rock2.svg" alt="Rock 2" className="img-fluid" />
                    </div>
                    <div className="rock3">
                        <img src="images/rock3.svg" alt="Rock 3" className="img-fluid" />
                    </div>
                    <div className="rock4">
                        <img src="images/rock4.svg" alt="Rock 4" className="img-fluid" />
                    </div>
                    <div className="rock5">
                        <img src="images/rock5.svg" alt="Rock 5" className="img-fluid" />
                    </div>
                    <div className="rock6">
                        <img src="images/rock6.svg" alt="Rock 6" className="img-fluid" />
                    </div>
                </div>
                <div className="bear1">
                    <img src="images/bear1.png" alt="Bear 1" className="img-fluid" />
                </div>
                <div className="bear content-wrapper">
                    <div className="container-fluid custom-container">
                        <div className="title">Welcome to the Global Bears Club!</div>
                        <p>
                            After working behind the scenes for months, we finally unveil the
                            Global Bears Club project to the world.
                        </p>
                    </div>
                </div>
                <div className="world">
                    <img src="images/world.png" alt="world 1" className="img-fluid" />
                </div>
                <div className="world-content content-wrapper">
                    <div className="container-fluid custom-container">
                        <div className="title">What We Do</div>
                        <p>
                            The Global Bears represents a unique collection of 3D bears via
                            the Ethereum Blockchain ready to dominate the NFT world and the
                            Metaverse.
                        </p>
                    </div>
                </div>
                <div className="jungle">
                    <img src="images/jungle.png" alt="jungle 1" className="img-fluid" />
                </div>
                <div className="jungle-content center-content content-wrapper">
                    <div className="container-fluid custom-container">
                        <div className="title">What are Global Bears?</div>
                        <div className="content">
                            <p>
                                The year is 2100 and the Arctic region is completely melted
                                away. Sea levels are intensely rising faster than normal. The
                                inner cities are submerged under water. Countries look more like
                                islands now. Earth is scorching hot. Living here in the Northern
                                & Southern Poles has been nearly impossible. In the distance a
                                small iceberg is seen to be still floating. Or is it a boat?
                                Scientists have suggested all polar animals are extinct by now,
                                but I see something moving on top of this object that does not
                                seem human-like… Could it be?
                            </p>
                            <h3>A GLOBAL BEAR!!</h3>
                        </div>
                        <div className="bear-home content">
                            <p>
                                As of 2020, Polar Bears are extremely vulnerable when it comes to the “Extinction Risk”. With just 20-30k left in the entire world, they face a growing issue in climate change and loss of environmental habitat. Due to climate change the Arctic is heating up twice as fast as anywhere else on the planet, shrinking the Arctic sea ice cover by 14% per decade. Compared to the median sea ice cover recorded between 1981-2010, we have lost about 770,000 square miles, an area larger than Alaska and California combined.
                            </p>
                        </div>
                        <div className="bear-home-inner content">
                            <p>
                                This Global Bears mission is to find distant relatives around the world and bring awareness to this world issue. He is the last of his kind dating in 2100 where Scientists (from 2021) believe Global Bears will become Extinct from the Wild. He travels on an Iceberg from continent to continent finding other Bears along his journey. He rallies a team from the Metaverse to travel back in time in an attempt to save the world…
                            </p>
                            <h3>They are The Global Bears Club.</h3>
                        </div>
                    </div>
                </div>
                <div className="home-outer">
                    <img
                        src="images/home-outer.png"
                        alt="Home Outer 1"
                        className="img-fluid"
                    />
                </div>

                <div className="home-inner">
                    <img
                        src="images/home-inner.png"
                        alt="Home Inner 1"
                        className="img-fluid"
                    />
                </div>

                <div className="road-map">
                    <img src="images/roadmap.png" alt="Road Map" className="img-fluid" />
                </div>
                <div className="road-map-content">
                    <div className="container-fluid custom-container">
                        <div className="title">
                            ROADMAP
                            <span>COLLECTION OF 1,100</span>
                        </div>
                        <div className="list one">
                            <h3>SEASON 1</h3>
                            <ul>
                                <li>Reveal video game integration (play to earn $)</li>
                                <li>Reveal Non-Profit Charity Fund</li>
                                <li>Funds sent to charity wallet</li>
                            </ul>
                            {/* <h3>25%</h3>
                            <ul>
                                <li>10 GBC bears will be minted & air dropped to random holders
                                </li>
                                <li>¼ Legendary Bear will be released on smart contract (1 ETH
                                    Prize to each Legendary holder once Sold Out )
                                </li>
                                <li>.5 ETH sent to 4 random holders</li>
                            </ul> */}
                        </div>
                        <div className="list two">
                            <h3>SEASON 2</h3>
                            <ul>
                                <li>GBC Merch Reveal </li>
                                <li>Global Bears Club NFT Staking Reveal!</li>
                                {/* <li>Surprise action for community</li> */}
                            </ul>
                            {/* <h3>50%</h3>
                            <ul>
                                <li>.5 ETH sent to 4 random holders</li>
                                <li>3/4 Legendary Bears will be released on smart contract
                                </li>
                                <li>GBC Custom Shoe Reveal</li>
                            </ul> */}
                        </div>
                        <div className="list three">
                            <h3>SEASON 3</h3>
                            <ul>
                                <li>Community DAO will be Established | holders will vote on Annual Events & Donations</li>
                                <li>Donate to Charity of choice</li>
                            </ul>
                            <h3>SEASON 4 </h3>
                            <ul>
                                <li> Global Bears part 2 - The Resurrection</li>
                                
                            </ul>
                            {/* <h3>75%</h3>
                            <ul>
                                <li>GBC Merch Reveal</li>
                                <li>Surprise action for community</li>
                                <li>.5 ETH will be sent to 6 random holders</li>
                                <li>Additional Funds added to Charity wallet</li>
                                <li>Custom GBC Merch giveaway to 7 random holders</li>
                                <li>4/4 Legendary Bears will be released on smart contract
                                </li>
                            </ul> */}
                        </div>
                        <div className="list four">
                            {/* <h3>SEASON 4 </h3>
                            <ul>
                                <li> Global Bears part 2 - The Resurrection</li>
                                
                            </ul> */}
                        </div>
                    </div>
                </div>
                <div className="bear2">
                    <img src="images/bear2.png" alt="Bear 2" className="img-fluid" />
                </div>
                <div className="bear3">
                    <img src="images/bear3.png" alt="Bear 3" className="img-fluid" />
                </div>
                <div className="bear4">
                    <img src="images/bear4.png" alt="Bear 4" className="img-fluid" />
                </div>
                <div className="bear5">
                    <img src="images/bear5.png" alt="Bear 5" className="img-fluid" />
                </div>
                <div className="bear6">
                    <img src="images/bear6.png" alt="Bear 6" className="img-fluid" />
                </div>
                <div className="end-left"></div>
                <div className="end-right"></div>
                <div className="teams-slider">
                    <div className="container-fluid custom-container">
                        <div className="section-title">
                            <div className="bg-icon">
                                <img src="images/title-bg.svg" alt="" className="img-fluid" />
                                <img src="images/yellow.svg" alt="" className="img-fluid" />
                            </div>
                            <h2>Our Team</h2>
                            <p>
                                It’s always been pretty simple for us. Great people make great
                                work. Meet the team.
                            </p>
                        </div>
                        <div className="team">
                            <div className="item">
                                <div className="image-bg">
                                    <img src="images/team-bg.svg" alt="" className="img-fluid" />

                                    <div className="image">
                                        <img src="images/tm-1.png" alt="" className="img-fluid" />
                                    </div>
                                </div>
                                <div className="content">
                                    <p>
                                        Hello I’m Global Ronnie aka Ronnie Banks, Co-Founder of
                                        Global Bears Club and Chief of Marketing & Finance.
                                    </p>
                                </div>
                                <div className="name">
                                    Global Ronnie<span></span>
                                </div>
                                <div className="social-links">
                                    <ul>
                                        <li>
                                            <a href="#">
                                                <div className="social icon-holder">
                                                    <Lottie loop animationData={lottieJson3} play />
                                                </div>
                                            </a>
                                        </li>
                                        {/* <li>
                                            <a href="#" className="icon-holder">
                                                <Lottie
                                                    loop
                                                    animationData={lottieJson4}
                                                    play
                                                />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" className="icon-holder">
                                                <Lottie
                                                    loop
                                                    animationData={fb}
                                                    play
                                                />
                                            </a>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                            <div className="item">
                                <div className="image-bg">
                                    <img src="images/team-bg.svg" alt="" className="img-fluid" />

                                    <div className="image">
                                        <img src="images/tm-2.png" alt="" className="img-fluid" />
                                    </div>
                                </div>
                                <div className="content">
                                    <p>
                                        I am one of the Co-Founders of Global Bears Club. My
                                        position in this project consists of Web & Discord support,
                                        on top of assisting our Social Media Marketing campaign.
                                    </p>
                                </div>
                                <div className="name">
                                    Global Birk<span></span>
                                </div>
                                <div className="social-links">
                                    <ul>
                                        <li>
                                            <a href="#">
                                                <div className="social icon-holder">
                                                    <Lottie loop animationData={lottieJson3} play />
                                                </div>
                                            </a>
                                        </li>
                                        {/* <li>
                                            <a href="#" className="icon-holder">
                                                <Lottie
                                                    loop
                                                    animationData={lottieJson4}
                                                    play
                                                />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" className="icon-holder">
                                                <Lottie
                                                    loop
                                                    animationData={fb}
                                                    play
                                                />
                                            </a>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                            <div className="item">
                                <div className="image-bg">
                                    <img src="images/team-bg.svg" alt="" className="img-fluid" />

                                    <div className="image">
                                        <img src="images/tm-3.png" alt="" className="img-fluid" />
                                    </div>
                                </div>
                                <div className="content">
                                    <p>
                                        I am one of the Co-Founders of Global Bears Club. I am the
                                        Crypto expert of this project as well as a discord
                                        admin/utility manager.
                                    </p>
                                </div>
                                <div className="name">
                                    Global Deliweez<span></span>
                                </div>
                                <div className="social-links">
                                    <ul>
                                        <li>
                                            <a href="#">
                                                <div className="social icon-holder">
                                                    <Lottie loop animationData={lottieJson3} play />
                                                </div>
                                            </a>
                                        </li>
                                        {/* <li>
                                            <a href="#" className="icon-holder">
                                                <Lottie
                                                    loop
                                                    animationData={lottieJson4}
                                                    play
                                                />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" className="icon-holder">
                                                <Lottie
                                                    loop
                                                    animationData={fb}
                                                    play
                                                />
                                            </a>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                            <div className="item">
                                <div className="image-bg">
                                    <img src="images/team-bg.svg" alt="" className="img-fluid" />

                                    <div className="image">
                                        <img src="images/tm-4.png" alt="" className="img-fluid" />
                                    </div>
                                </div>
                                <div className="content">
                                    <p>
                                        I am one of the Co-Founders of the Global Bears Club my
                                        position is Head Of Web Development & Art Development
                                    </p>
                                </div>
                                <div className="name">
                                    Global Drew<span></span>
                                </div>
                                <div className="social-links">
                                    <ul>
                                        <li>
                                            <a href="#">
                                                <div className="social icon-holder">
                                                    <Lottie loop animationData={lottieJson3} play />
                                                </div>
                                            </a>
                                        </li>
                                        {/* <li>
                                            <a href="#" className="icon-holder">
                                                <Lottie
                                                    loop
                                                    animationData={lottieJson4}
                                                    play
                                                />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" className="icon-holder">
                                                <Lottie
                                                    loop
                                                    animationData={fb}
                                                    play
                                                />
                                            </a>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                            <div className="item">
                                <div className="image-bg">
                                    <img src="images/team-bg.svg" alt="" className="img-fluid" />

                                    <div className="image">
                                        <img src="images/tm-5.png" alt="" className="img-fluid" />
                                    </div>
                                </div>
                                <div className="content">
                                    <p>
                                        I am the Dev on this project & IT executive with over 20
                                        years working with Fortune 500 Companies like AWS.
                                    </p>
                                </div>
                                <div className="name">
                                    Sascha Modersitzki<span></span>
                                </div>
                                <div className="social-links">
                                    <ul>
                                        <li>
                                            <a href="#">
                                                <div className="social icon-holder">
                                                    <Lottie loop animationData={lottieJson3} play />
                                                </div>
                                            </a>
                                        </li>
                                        {/* <li>
                                            <a href="#" className="icon-holder">
                                                <Lottie
                                                    loop
                                                    animationData={lottieJson4}
                                                    play
                                                />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" className="icon-holder">
                                                <Lottie
                                                    loop
                                                    animationData={fb}
                                                    play
                                                />
                                            </a>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                            <div className="item">
                                <div className="image-bg">
                                    <img src="images/team-bg.svg" alt="" className="img-fluid" />

                                    <div className="image">
                                        <img src="images/tm-6.png" alt="" className="img-fluid" />
                                    </div>
                                </div>
                                <div className="content">
                                    <p>
                                        I am a graphic designer and a 3d artist coming from the
                                        Dominican Republic, in 2010
                                    </p>
                                </div>
                                <div className="name">
                                    Eduardo de Jesús<span></span>
                                </div>
                                <div className="social-links">
                                    <ul>
                                        <li>
                                            <a href="#">
                                                <div className="social icon-holder">
                                                    <Lottie loop animationData={lottieJson3} play />
                                                </div>
                                            </a>
                                        </li>
                                        {/* <li>
                                            <a href="#" className="icon-holder">
                                                <Lottie
                                                    loop
                                                    animationData={lottieJson4}
                                                    play
                                                />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" className="icon-holder">
                                                <Lottie
                                                    loop
                                                    animationData={fb}
                                                    play
                                                />
                                            </a>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="faqs-section">
                <div className="container-fluid custom-container">
                    <div className="section-title">
                        {/* <div className="q-image">
                            <img src="/images/q-mark.svg" alt="" className="img-fluid" />
                            <span></span>
                        </div> */}
                        <div className="title">
                            <span>Frequently Asked Questions</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="image">
                                {/* {stateImage.faq1 ? (
                  <img src="images/faq-bear.png" alt="" className="img-fluid" />
                ) : stateImage.faq2 ? (
                  <img src="images/faq1.png" alt="" className="img-fluid" />
                ) : stateImage.faq3 ? (
                  <img src="images/faq2.png" alt="" className="img-fluid" />
                ) : stateImage.faq4 ? (
                  <img src="images/faq3.png" alt="" className="img-fluid" />
                ) : stateImage.faq5 ? (
                  <img src="images/faq4.png" alt="" className="img-fluid" />
                ) : stateImage.faq6 ? (
                  <img src="images/faq5.png" alt="" className="img-fluid" />
                ) : (
                  <img src="images/faq-bear.png" alt="" className="img-fluid" />
                )} */}
                                <img src={`images/${imageUrl}.png`} alt="" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="content-faqs">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header onClick={() => handleImage("faq-bear")}>
                                            What is an NFT?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            NFT stands for 'non-fungible token’. An NFT is basically
                                            data that is accounted for in a digital ledger, and that
                                            data represents something specific and unique. An NFT can,
                                            for example, represent a unique piece of art or a game
                                            token.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header onClick={() => handleImage("faq1")}>
                                            Why Global Bears Club?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            Here at GBC, we provide unique art with tangible utilities
                                            for our customers. We plan on expanding across the Globe
                                            and expressing the word about Global Warming. Animals
                                            across the world are becoming endangered or extinct at an
                                            alarming rate. To initiate change, we will be donating to
                                            some Non-Profits of community choice. - The market is
                                            saturated with all mimicked projects and we hope to bring
                                            a fresh face to the market.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header onClick={() => handleImage("faq2")}>
                                            How many Global Bears will be available?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        The collection will consist of 1,100 Global Bears
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header onClick={() => handleImage("faq3")}>
                                            When will the collection drop?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            Public sale will be available: March 10th
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header onClick={() => handleImage("faq5")}>
                                            What is a Meta Mask wallet?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            MetaMask allows users to store and manage account keys,
                                            broadcast transactions, send and receive Ethereum-based
                                            cryptocurrencies and tokens, and securely connect to
                                            decentralized applications through a compatible web
                                            browser or the mobile app's built-in browser. Go to
                                            MetaMask.io and select from Android or iOS for mobile
                                            application and select Chrome for desktop. You can also go
                                            directly to the Chrome store, Google Play store, or Apple
                                            App Store
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer">
                <div className="container-fluid custom-container">
                    <div className="footer-content">
                        <div className="footer-content__left">
                            <h2 className="footer-content__title">
                                <span>Global Bear Club</span>
                                {/* <img src="assets/images/union@2x.png" alt="union" /> */}
                            </h2>
                            <p className="footer-content__text">Lets Go Global</p>
                            <div className="social-links">
                                <ul>
                                    <li>
                                        <a href="#">
                                            <div className="social icon-holder">
                                                <Lottie loop animationData={lottieJson3} play />
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="icon-holder">
                                            <Lottie loop animationData={lottieJson4} play />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="icon-holder">
                                            <Lottie loop animationData={fb} play />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="footer-content__right">
                            <div className="footer-content__wrapper">
                                <nav className="footer-content__nav">
                                    <a className="footer-content__nav-link" href="/">
                                        Home
                                    </a>
                                    <a className="footer-content__nav-link" href="#roadmap">
                                        Roadmap
                                    </a>
                                    <a className="footer-content__nav-link" href="#faq">
                                        FAQ
                                    </a>
                                </nav>
                                {/* <nav className="footer-content__nav">
                                    <a className="footer-content__nav-link" href="#lore">Lore</a>
                                    <a className="footer-content__nav-link" href="#roadmap">Roadmap</a>
                                    <a className="footer-content__nav-link" href="#faq">FAQ</a>
                                </nav> */}
                            </div>
                        </div>
                    </div>
                    <div className="footer-copyright">
                        <p className="footer-copyright__text">
                            Copyright © 2022. All rights reserved
                        </p>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Home;

import Logo from '../assets/images/logo.png'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
function Header() {
    return (
        <div className="header">
            <Navbar className="fixed-top" expand="lg">
                <div className="container-fluid custom-container">
                    <Navbar.Brand href="#home"><img src={Logo} alt="Logo" className="img-fluid logo" /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <Nav.Link href="#home">Home</Nav.Link>
                            <Nav.Link href="#link">Opensea</Nav.Link>
                            <Nav.Link href="#link">Twitter</Nav.Link>
                            <Nav.Link href="#link">Discord</Nav.Link>
                            <Nav.Link href="#link">Roadmap</Nav.Link>
                            <Nav.Link className="btn btn-transparent" href="#link">Mint Now</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </div>
            </Navbar>
            {/* <nav className="navbar navbar-expand-lg fixed-top">
                <div className="container-fluid custom-container">
                    <a className="navbar-brand" href="#">
                        <img src={Logo} alt="Logo" className="img-fluid logo" />
                    </a>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="#"
                                >Home</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Opensea</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Twitter</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Discord</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Roadmap</a>
                            </li>
                            <li className="nav-item button">
                                <a className="nav-link btn btn-transparent" href="#">Mint Now</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav> */}
        </div>
    );
}

export default Header;

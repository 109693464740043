import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Home from './components/home'
import Header from './components/Header';

function App() {
  return (
    <div className="wrapper">
      <Header/>
     <Home/>
    </div>
  );
}

export default App;
